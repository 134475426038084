<template>
    <OModal ref="relDocDlg">
        <div class="modal-dialog modal-xl row-container" style="max-width:80%;">
            <div class="modal-content row-container">
                <div class="modal-header">
                    <h5 class="modal-title">{{$t('Add Related Document')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body row-container">
                    <div class="row justify-content-start pb-2">
                        <div class="col-auto">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" @change="handleObsoleteSwitchChange">
                                <label class="form-check-label" for="flexSwitchCheckDefault1">{{$t('Include Obsolete')}}</label>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault2" v-model="restrictToContext" @change="handleRestrictToContextSwitchChange">
                                <label class="form-check-label" for="flexSwitchCheckDefault2">{{$t('Restrict to Context')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row-container" style="max-height:800px;">
                        <o-data-grid :data-object="dsDocLookup" hide-grid-menu hideMultiselectColumn :row-style="setRowStyle" >
                            <o-column width="130" colId="LTL" v-slot="{row}" headerName="Link to Latest" class="d-flex justify-content-center align-items-center">
                                <i v-if="!row.LinkToLatest && !row.LinkToRevision" class="bi bi-circle" @click="addLinkToLatest(row)"></i>
                                <i v-if="row.LinkToLatest && !row.LinkToRevision" class="bi bi-record-circle"></i>
                            </o-column>
                            <o-column width="130" colId="LTR" v-slot="{row}" headerName="Link to Revision" class="d-flex justify-content-center align-items-center">
                                <i v-if="!row.LinkToRevision && !row.LinkToLatest" class="bi bi-circle" @click="addLinkToRevision(row)"></i>
                                <i v-if="row.LinkToRevision && !row.LinkToLatest" class="bi bi-record-circle"></i>
                            </o-column>
                            <o-column field="ID" width="120"></o-column>
                            <o-column field="OrgUnit" width="200"></o-column>
                            <o-column field="Name" width="300"></o-column>
                            <o-column field="Title" width="500"></o-column>
                            <o-column field="Status" width="200"></o-column>
                            <o-column field="Rev" width="80"></o-column>
                        </o-data-grid>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-1">
                            <div v-for="row in props.insertDataObject.data" class="d-inline">
                                <span class="badge rounded-pill me-2" style="background-color:#eee;color:black;">
                                    {{row.Document}} (Rev: <span v-if="!row.LinkToLatest">{{row.Rev}}</span><span v-if="row.LinkToLatest">Latest</span>, Title: {{row.DocumentTitle}})
                                    <i class="bi bi-x-circle float-end ms-1" role="button" @click="deleteRelation(row)"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('Close')}}</button>
                </div>
            </div>
        </div>
    </OModal>

    <slot name="target" :target="el => target = el">
        <a ref="target">
            <slot />
        </a>
    </slot>
</template>

<script setup>
    import { ref, onMounted, onBeforeUnmount } from 'vue';
    import { context } from 'o365-modules';

    const props = defineProps({
        masterDataObject: Object,
        insertDataObject: Object,
        updateOnCloseDataObject: Object,
        lookupViewName: String,
        masterDetailField: {
            type: String,
            default: "Document_ID"
        },
        masterDetailMasterField: {
            type: String,
            default: "ID"
        },
        insertDocumentIDField: {
            type: String,
            default: "RelatedDocument_ID"
        },
    });

    const target = ref(null);

    let limitToContext = ref(true), includeObsolete = ref(false);
    let restrictToContext = ref(true)
    
    const relDocDlg = ref(null)

    const dsDocLookupDef = {
        id: 'dsDocLookup',
        viewName: props.lookupViewName,
        appId: props.masterDataObject.appId,
        maxRecords: 50,
        loadRecents: true,
        distinctRows: true,
        selectFirstRowOnLoad: true,
        masterDataObject_ID: props.masterDataObject.id,
        masterDetailDefinition: [{
            detailField: props.masterDetailField,
            masterField: props.masterDetailMasterField,
            operator: "equals"
        }],
        fields:
            [
                {name: "PrimKey", type: "string"},
                {name: "ID", type: "number" },
                {name: "OrgUnit", type: "string"},
                {name: "Name", type: "string", sortOrder: 1, sortDirection: "asc"},
                {name: "Title", type: "string"},
                {name: "Status", type: "string"},
                {name: "Rev", type: "string", sortOrder: 2, sortDirection: "asc"},
                {name: "Obsolete", type: "date"},
                {name: "OrgUnitIdPath", type: "string"},
                {name: "LinkToLatest", type: "bit"},
                {name: "LinkToRevision", type: "bit"},
            ]
    } 

    

    const dsDocLookup = $getOrCreateDataObject(dsDocLookupDef);

    const getWhereClause = () => {
        let vWhereClause = "";        
        vWhereClause += (restrictToContext.value) ? `OrgUnitIdPath LIKE '${context.idPath}%'` : `1 = 1`;
        vWhereClause += (!includeObsolete.value) ? ` AND Obsolete IS NULL` : ``;
        return vWhereClause;
    }

    const reloadMainDS = () => {
        dsDocLookup.recordSource.whereClause = getWhereClause();
        dsDocLookup.load();
    }

    const openRelDocDlg = () => {
        relDocDlg.value.show();
    }

    const handleObsoleteSwitchChange = (e) => {
        includeObsolete.value = e.target.checked;
        reloadMainDS();
    }

    const handleRestrictToContextSwitchChange = (e) => {
        reloadMainDS();
    }

    const setRowStyle = (pRow, pinned) => {
        if(pRow.Obsolete != null){
            return 'background:#ccc; font-style:italic;'
        }
    };

    const addLinkToLatest = (pRow) => {
        props.insertDataObject.createNew({[props.insertDocumentIDField]:pRow.ID,LinkToLatest:true});
        props.insertDataObject.save().then(() => {
            dsDocLookup.refreshRow()
            if(props.updateOnCloseDataObject){
                props.updateOnCloseDataObject.load();
            }
        });
    }

    const addLinkToRevision = (pRow) => {
        props.insertDataObject.createNew({[props.insertDocumentIDField]:pRow.ID,LinkToRevision:true});
        props.insertDataObject.save().then(() => { 
            dsDocLookup.refreshRow()
            if(props.updateOnCloseDataObject){
                props.updateOnCloseDataObject.load();
            }
        });
    }

    const deleteRelation = (pRow) => {
        props.insertDataObject.deleteItem(pRow).then(() => {
            reloadMainDS();
        });
    }

    reloadMainDS();

    onMounted(() => {
        if (target.value) {
            target.value.addEventListener("click", openRelDocDlg);
        }
    });

    onBeforeUnmount(() => {
        if (target.value) {
            target.value.removeEventListener("click", openRelDocDlg);
        }
    });
</script>